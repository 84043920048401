import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'

import { media } from '../theme'
import { Container, Row, Column } from '../components/Grid'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'
import logo from '../images/logo-tagline.png'

const StyledContainer = styled(Container)`
  padding: ${rem(36)} ${rem(21)};
  ${media('sm')`
    padding: ${rem(56)} ${rem(28)};
  `}
`
const Title = styled.h1`
  color: ${theme('colors.primary')};
  font-size: ${rem(32)};
  line-height: 1.3;
  ${media('sm')`
    font-size: ${rem(36)};
  `}
`
const Subtitle = styled.p`
  text-align: center;
`

const NotFoundPage = ({ location: { pathname: path } }) => (
  <Layout header="dark">
    <SEO
      title="Sidan kunde inte hittas"
      description="Sidan du söker kunde inte hittas"
      path={path}
      image={logo}
    />
    <StyledContainer>
      <Title>Sidan kunde inte hittas</Title>
      <Subtitle>Sidan du söker kunde inte hittas</Subtitle>
    </StyledContainer>
  </Layout>
)

export default NotFoundPage
